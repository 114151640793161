.credit-cards-title {
    font-size: 0.7rem;
    text-transform: uppercase;
    border-bottom: 2px solid lightgray;
    padding-bottom: 5px;
}

.credit-cards-text {
    font-size: 2rem;
}

.account-credits {
    border-bottom: 1px solid lightgray;
    padding-bottom: 15px;
    margin-bottom: 15px;
}