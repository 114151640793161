

/* Variables */
:root {
    --gray-offset: rgba(0, 0, 0, 0.03);
    --gray-border: rgba(0, 0, 0, 0.15);
    --gray-light: rgba(0, 0, 0, 0.4);
    --gray-mid: rgba(0, 0, 0, 0.7);
    --gray-dark: rgba(0, 0, 0, 0.9);
    --body-color: var(--gray-mid);
    --headline-color: var(--gray-dark);
    --accent-color: #0066f0;
    --body-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    --radius: 6px;
    --form-width: 343px;
  }

.sr-main {
    width: var(--form-width)
}
  
  /* Base */
  * {
    box-sizing: border-box;
  }

  .checkout-image-stack {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: auto auto;
  }
  
  .checkout-image-stack img {
    border-radius: var(--radius);
    background-color: var(--gray-border);
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    transition: all 0.8s ease;
    opacity: 0;
  }
  
  .checkout-image-stack img:nth-child(1) {
    transform: translate(12px, -12px);
    opacity: 1;
  }
  .checkout-image-stack img:nth-child(2) {
    transform: translate(-24px, 16px);
    opacity: 1;
  }
  .checkout-image-stack img:nth-child(3) {
    transform: translate(68px, -100px);
    opacity: 1;
  }

  
  /* Layout */

  .sr-root {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 980px;
    padding: 48px;
    align-content: center;
    justify-content: center;
    height: auto;
    margin: 0 auto;
  }
  .sr-header {
    margin-bottom: 32px;
  }
  .sr-payment-summary {
    margin-bottom: 20px;
  }
  .sr-main,
  .sr-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-self: center;
  }
  .sr-main {
    width: var(--form-width);
  }
  .sr-content {
    padding-left: 48px;
  }
  .sr-header__logo {
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
  }
  .sr-legal-text {
    color: var(--gray-light);
    text-align: center;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }
  .sr-field-error {
    color: var(--accent-color);
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }
  
  .sr-field-success {
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }
  
  /* Form */
  .sr-form-row {
    margin: 16px 0;
  }
  label {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 8px;
    display: inline-block;
  }
  
  /* Inputs */
  .sr-input,
  .sr-select,
  input[type="text"] {
    border: 1px solid var(--gray-border);
    border-radius: var(--radius);
    padding: 5px 12px;
    height: 44px;
    width: 100%;
    transition: box-shadow 0.2s ease;
    background: white;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: #32325d;
  }
  .sr-input:focus,
  input[type="text"]:focus,
  button:focus,
  .focused {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
      0 0 0 4px rgba(50, 151, 211, 0.3);
    outline: none;
    z-index: 9;
  }
  .sr-input::placeholder,
  input[type="text"]::placeholder {
    color: var(--gray-light);
  }
  
  /* Checkbox */
  .sr-checkbox-label {
    position: relative;
    cursor: pointer;
  }
  
  .sr-checkbox-label input {
    opacity: 0;
    margin-right: 6px;
  }
  
  .sr-checkbox-label .sr-checkbox-check {
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: white;
    border: 1px solid var(--gray-border);
    border-radius: 4px;
    transition: all 0.2s ease;
  }
  
  .sr-checkbox-label input:focus ~ .sr-checkbox-check {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
      0 0 0 4px rgba(50, 151, 211, 0.3);
    outline: none;
  }
  
  .sr-checkbox-label input:checked ~ .sr-checkbox-check {
    background-color: var(--accent-color);
    background-image: url("https://storage.googleapis.com/stripe-sample-images/icon-checkmark.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: -1px -1px;
  }
  
  /* Select */
  .sr-select {
    display: block;
    height: 44px;
    margin: 0;
    background-image: url("https://storage.googleapis.com/stripe-sample-images/icon-chevron-down.svg");
    background-repeat: no-repeat, repeat;
    background-position: right 12px top 50%, 0 0;
    background-size: 0.65em auto, 100%;
  }
 
  .sr-select::-ms-expand {
    display: none;
  }
  .sr-select:hover {
    cursor: pointer;
  }
  .sr-select:focus {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
      0 0 0 4px rgba(50, 151, 211, 0.3);
    outline: none;
  }
  .sr-select option {
    font-weight: 400;
  }
  .sr-select:invalid {
    color: var(--gray-light);
    background-opacity: 0.4;
  }
  
  /* Combo inputs */
  .sr-combo-inputs {
    display: flex;
    flex-direction: column;
  }
  .sr-combo-inputs input,
  .sr-combo-inputs .sr-select {
    border-radius: 0;
    border-bottom: 0;
  }
  .sr-combo-inputs > input:first-child,
  .sr-combo-inputs > .sr-select:first-child {
    border-radius: var(--radius) var(--radius) 0 0;
  }
  .sr-combo-inputs > input:last-child,
  .sr-combo-inputs > .sr-select:last-child {
    border-radius: 0 0 var(--radius) var(--radius);
    border-bottom: 1px solid var(--gray-border);
  }
  .sr-combo-inputs > .sr-combo-inputs-row:last-child input:first-child {
    border-radius: 0 0 0 var(--radius);
    border-bottom: 1px solid var(--gray-border);
  }
  .sr-combo-inputs > .sr-combo-inputs-row:last-child input:last-child {
    border-radius: 0 0 var(--radius) 0;
    border-bottom: 1px solid var(--gray-border);
  }
  .sr-combo-inputs > .sr-combo-inputs-row:first-child input:first-child {
    border-radius: var(--radius) 0 0 0;
  }
  .sr-combo-inputs > .sr-combo-inputs-row:first-child input:last-child {
    border-radius: 0 var(--radius) 0 0;
  }
  .sr-combo-inputs > .sr-combo-inputs-row:first-child input:only-child {
    border-radius: var(--radius) var(--radius) 0 0;
  }
  .sr-combo-inputs-row {
    width: 100%;
    display: flex;
  }
  
  .sr-combo-inputs-row > input {
    width: 100%;
    border-radius: 0;
  }
  
  .sr-combo-inputs-row > input:first-child:not(:only-child) {
    border-right: 0;
  }
  
  .sr-combo-inputs-row:not(:first-of-type) .sr-input {
    border-radius: 0 0 var(--radius) var(--radius);
  }
  
  /* Buttons and links */
  button {
    background: var(--accent-color);
    border-radius: var(--radius);
    color: white;
    border: 0;
    padding: 12px 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }
  button:disabled {
    opacity: 0.5;
    cursor: none;
  }
  
  .sr-payment-form button,
  .fullwidth {
    width: 100%;
  }
  

  
  /* Code block */
  .sr-callout {
    background: var(--gray-offset);
    padding: 12px;
    border-radius: var(--radius);
    max-height: 200px;
    overflow: auto;
  }
  code,
  pre {
    font-family: "SF Mono", "IBM Plex Mono", "Menlo", monospace;
    font-size: 12px;
  }
  
  /* todo: spinner/processing state, errors, animations */
  
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: var(--accent-color);
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: var(--accent-color);
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  /* Animated form */
  
  .sr-root {
    animation: 0.4s form-in;
    animation-fill-mode: both;
    animation-timing-function: ease;
  }
  
  .sr-payment-form .sr-form-row {
    animation: 0.4s field-in;
    animation-fill-mode: both;
    animation-timing-function: ease;
    transform-origin: 50% 0%;
  }
  
  /* need saas for loop :D  */
  .sr-payment-form .sr-form-row:nth-child(1) {
    animation-delay: 0;
  }
  .sr-payment-form .sr-form-row:nth-child(2) {
    animation-delay: 60ms;
  }
  .sr-payment-form .sr-form-row:nth-child(3) {
    animation-delay: 120ms;
  }
  .sr-payment-form .sr-form-row:nth-child(4) {
    animation-delay: 180ms;
  }
  .sr-payment-form .sr-form-row:nth-child(5) {
    animation-delay: 240ms;
  }
  .sr-payment-form .sr-form-row:nth-child(6) {
    animation-delay: 300ms;
  }
  
  .hidden {
    display: none;
  }
  
  @keyframes field-in {
    0% {
      opacity: 0;
      transform: translateY(8px) scale(0.95);
    }
    100% {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }
  }
  
  @keyframes form-in {
    0% {
      opacity: 0;
      transform: scale(0.98);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Responsiveness */
  @media (max-width: 720px) {
    .sr-root {
      flex-direction: column;
      justify-content: flex-start;
      padding: 12px 20px;
      min-width: 320px;
    }
  
    .sr-header__logo {
      background-position: center;
    }
  
    .sr-payment-summary {
      text-align: center;
    }
  
    .sr-content {
      display: none;
    }
  
    .sr-main {
      width: 100%;
    }
  }

  /* Usage Banner */
  .banner {
    max-width: 825px;
    margin: 0 auto;
    padding: 0 22px;
    font-size: 14px;
    background: white;
    color: #6a7c94;
    border-radius: 22px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
  }