.site {
    display: flex;
    height: 100vh;
    flex-direction: column;
  }
  
.site-content {
    flex: 1 0 auto;
    padding: var(--space) var(--space) 0;
    width: 100%;
  }

  .site-content:after {
    content: '\00a0';
    display: block;
    margin-top: var(--space);
    height: 0;
    visibility: hidden;
}


.page-title {
    color: #ff1342;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0.33em;
    text-align: center;
}

.page-header-block {
    max-width: 700px;
    text-align: center;
    padding: 1em;
    margin-top: 1em;
}


.apple-download {
    background-image:url('./assets/Download_App_Store_Black.svg');
    background-repeat: no-repeat;
    height:40px;
    width:120px;
    border: none;
    outline: none;
}

.card-img-bottom {
    width: 75%;
    height: 50vh;
    object-fit: cover;
}

h1, h2, h3, h4, h5, h6, td, .material-icons {
    color: #404C60;
}

.footer {
    background-color: darkgrey ;
    color: white;
}

.footer h6 {
    color: white;
}

.footer a {
    color: white;
}


.check li::before {
    font-family: FontAwesome;
    font-size: 1.5rem;
    position: absolute;
    left: 8px;
    color: #ff1342;
    content: "\F00c";
}


