

.navbar-brand {
    font-family: 'Titillium Web';
    font-size: 2.0rem;
    font-weight: lighter;
    margin: 0px;
}

.navbar-brand .nav-link {
    padding-left: 0px;
}

.navbar-brand img {
    width: 175px;
    height: 38px;
}

.navbar-custom {
    background-color: #ff1342;
    color: #ffffff;
}

.navbar-brand a,
.navbar-custom a {
    color: #fff;
    text-decoration: none;
}


 .navbar-custom .nav-link {
     color: #fff;
 }




 .navbar-custom .navbar-toggler {
    border-color: white;
    outline: none;
}

.navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    color: white;
}