:root {
    --gray-border: rgba(0, 0, 0, 0.35);
    --radius: 6px;
  }

.landing-header {
    background: linear-gradient(#ff1342, #8f0101);
}

.header {
    font-size: 1.7rem;
}

.features-image-stack {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: auto auto;
    max-width: 250px;
  }
  
  .features-image-stack svg {
    border-radius: var(--radius);
    background-color: var(--gray-border);
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    transition: all 0.8s ease;
    opacity: 0;
    color:#888888;
  }
  
  .features-image-stack svg:nth-child(1) {
    transform: translate(8px, 5px);
    opacity: 1;
  }
  .features-image-stack svg:nth-child(2) {
    transform: translate(0px, 12px);
    opacity: 1;
  }
  .features-image-stack svg:nth-child(3) {
    transform: translate(80px, -15px);
    opacity: 1;
  }


